html {
  height: 100%;
}
body.altai-theme-admin {
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  display: flex;
  flex: 1;
  height: 100%;
  margin: 0;
}
.altai-theme-admin #bodyView {
  display: flex;
  flex: 1;
  flex-direction: column;
}
